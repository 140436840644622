body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica, sans-serif;
    overflow-x: hidden;
}

#C {
    position: absolute;
}

.ctn {
    position: relative;
    width: 360px;
    height: 640px;
}

#scoreboard {
    position: absolute;
    top: 540px;
    width: 360px;
    text-align: center;
    font-size: 50px;
    color: #333333;
}

#start-screen {
position: absolute;
top: 20px;
}

.top-screen {
    visibility: hidden;
    position: absolute;
    width: 360px;
    height: 640px;
    opacity: 0;
    transition: opacity 1s;
    text-align: center;
}

.top-screen-title {
    width: 280px;
    background-color: #333333;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 40px;
    letter-spacing: 4px;
    margin: auto;
    margin-top: 200px;
}

.top-screen-sub-title {
    width: 280px;
    background-color: #333333;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 20px;
    letter-spacing: 4px;
    margin: auto;
    margin-top: 10px;
}

.start-button{
    width: 100px;
    background-color: #333333;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 20px;
    letter-spacing: 4px;
    transition: background-color 1s;
    margin: 100px auto 10px;
}

.top-screen-button{
    width: 100px;
    background-color: #333333;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 20px;
    letter-spacing: 4px;
    transition: background-color 1s;
    margin: 140px auto 5px;
}

.top-screen-sec-button{
    width: 100px;
    background-color: #333333;
    color: #ffffff;
    padding: 10px 20px;
    font-size: 18px;
    letter-spacing: 2 px;
    transition: background-color 1s;
    margin: 5px auto;
}

.top-screen-sec-button:hover ,.top-screen-button:hover,.start-button:hover{
    background-color: #ffffff;
    color: #333333;
    cursor: pointer;
}

#ctrl-ctn {
    position: absolute;
    top: 300px;
    left: 20px;
    opacity: 0;
    transition: opacity 1s;
}

.ctrl-btn {
    background-color: #333333;
    color: #ffffff;
    text-align: center;
    font-size: 18px;
    padding: 8px 16px;
    margin: 10px;
}

#open,
#close{
    cursor: pointer;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    padding: 12px;
    margin: 16px auto 0;
    background: #4caf50;
    color: white;
}

#reset_button{
    cursor: pointer;
    width: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
    padding: 12px;
    margin: 16px auto 0;
    background: #fb0017;
    color: white;
}

#mask {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
}

#modal {
    background: #fff;
    color: #555;
    width: 300px;
    padding: 40px;
    border-radius: 4px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 2;
    transition: 0.4s;
}

#modal p {
    margin: 0 0 20px;
}

#mask.hidden {
    display: none;
}

#modal.hidden {
    transform: translate(0, -500px);
}